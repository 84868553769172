import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Layout from "../ui/Layout/Layout";
import SuccessPage from "../SuccessPage/SuccessPage";
import StepTwo from "./Steps/StepTwo/StepTwo";
import StepFour from "./Steps/StepFour/StepFour";
import StepFive from "./Steps/StepFive/StepFive";
import { screenResize } from "../../reducers/windowWidthReducer";
import StepOneNoAllaria from "./Steps/StepOne/StepOneNoAllaria";
import StepOneAllaria from "./Steps/StepOne/StepOneAllaria";
import { addMessage } from "../utils/addMessage";
import ToastMessage from "../ui/ToastMessage";
import { updateLocalStorageLoaded } from "../../reducers/localStorageReducer";
import TransitionStepsWrapper from "../ui/TransitionStepsWrapper/TransitionStepsWrapper";
import StepThreePart1 from "./Steps/StepThree/StepThreePart1";
import StepThreePart2 from "./Steps/StepThree/StepThreePart2";
import StepThreePart3 from "./Steps/StepThree/StepThreePart3";
import CancelOpeningModal from "../ui/CancelOpeningModal/CancelOpeningModal";
import PEPModal from "../ui/PEPModal/PEPModal";
import ModalDNI from "../ui/DNI/ModalDNI";
import ModalDNITramit from "../ui/DNI/modalDNITramit";
import AllariaNote from "../ui/AllariaNote/AllariaNote";
import { onUpdateJuridical } from "../../reducers/accountOpeningForm";

const CurrentStep: React.FC<{
  typeOfOpening: string;
  currentStep: number;
  stepThreeProgress: number;
}> = ({ typeOfOpening, currentStep, stepThreeProgress }) => {
  //Complete with the components of each step
  if (typeOfOpening === "WITHOUT_ALLARIA_ACCOUNT") {
    switch (currentStep) {
      case 1:
        return <StepOneNoAllaria />;
      case 2:
        return <StepTwo />;
      case 3:
        switch (stepThreeProgress) {
          case 1:
            return <StepThreePart1 />;
          case 2:
            return <StepThreePart2 />;
          case 3:
            return <StepThreePart3 />;
          default:
            return null;
        }
      case 4:
        return <StepFour />;
      case 5:
        return <StepFive />;
      default:
        return null;
    }
  } else {
    switch (currentStep) {
      case 1:
        return <StepOneAllaria />;
      case 2:
        return <StepTwo />;
      default:
        return null;
    }
  }
};

const FormPage = () => {
  const {
    typeOfOpening,
    currentStep,
    completedForm,
    formWithoutAllariaAccount,
    animationDirection,
    isLoading,
  } = useAppSelector((state) => state.accountOpeningForm);
  const { localStorageLoaded } = useAppSelector((state) => state.localStorage);

  const dispatch = useAppDispatch();
  const stepThreeProgress = formWithoutAllariaAccount[3].currentStepProgress;

  const [showSuccessView, setShowSuccessView] = useState<boolean>(false);

  useEffect(() => {
    // Window width
    window.addEventListener("resize", () =>
      dispatch(screenResize(window.innerWidth))
    );
    if (
      currentStep > 3 ||
      (currentStep === 3 && formWithoutAllariaAccount[3].gender)
    ) {
      dispatch(updateLocalStorageLoaded());
    }
  }, []);

  useEffect(() => {
    if (localStorageLoaded) {
      addMessage({
        message:
          "Guardamos los datos ingresados la última vez que intentaste crear una cuenta con tu email. Continuá con la apertura.",
        messageId: "localStorageMessage",
        type: "info",
        showOverPage: true,
        position: "top-right",
      });
    }
  }, [localStorageLoaded]);

  useEffect(() => {
    if (completedForm && !showSuccessView) {
      setShowSuccessView(true);
    }
  }, [completedForm]);

  // JURIDICAL ALLARIA PATH
  // If the url has the parameter PJ, the type of opening is set to WITH_ALLARIA_ACCOUNT
  // This is the allaria flow coming from the business onboarding

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const PJ = Boolean(params.PJ);

  useEffect(() => {
    if (PJ) {
      dispatch(onUpdateJuridical(true));
    } else {
      dispatch(onUpdateJuridical(false));
    }
  }, []);

  return (
    <>
      {showSuccessView ? (
        <SuccessPage setShowSuccessView={setShowSuccessView} />
      ) : (
        // <AnimatePresence exitBeforeEnter custom={animationDirection}>
        <Layout key={`${currentStep}-${stepThreeProgress}`}>
          <ToastMessage
            messageId="localStorageMessage"
            limit={1}
            closeButton={false}
            closeOnClick={true}
            draggable={false}
          />
          {!isLoading && (
            <TransitionStepsWrapper
              animationDirection={animationDirection}
              currentStep={currentStep}
            >
              {/* <StepHeader/> */}
              <CurrentStep
                typeOfOpening={typeOfOpening}
                currentStep={currentStep}
                stepThreeProgress={stepThreeProgress}
              />
            </TransitionStepsWrapper>
          )}
        </Layout>
        //  </AnimatePresence>
      )}
      <CancelOpeningModal />
      <ModalDNI />
      <ModalDNITramit />
      <AllariaNote />
      <PEPModal />
    </>
  );
};

export default FormPage;
