import { LegacyRef, useEffect, useState, useRef, ReactNode } from "react";
import {
  AwsCredentialProvider,
  FaceLivenessDetectorCore,
} from "@aws-amplify/ui-react-liveness";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieJson from "./success-lottie.json";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { closeModal, openModal } from "../../../../../reducers/modalReducer";
import {
  getSessionId,
  retrieveLivenessResult,
  uploadPhoto,
  validateRenaperImage,
} from "../../../../../connectors/connectors";
import QRCode from "qrcode";
import Button from "../../../../ui/Button/Button";
import InfoAlert from "../../../../ui/InfoAlert/InfoAlert";
import MinimumRequirementsModal from "../MinimumRequirementsModal/MinimumRequirementsModal";
import { livenessTexts } from "./LivenessTexts";

import errorIcon from "../../../../../assets/img/icon-error-cross-filled.svg";
import successIcon from "../../../../../assets/img/icon-sucess-filled.svg";
import validExample from "../../../../../assets/img/StepThree/valid_example.svg";
import invalidExample from "../../../../../assets/img/StepThree/invalid_example.svg";
import cameraIcon from "../../../../../assets/img/StepThree/camera.svg";
import warningIcon from "../../../../../assets/img/icon-warning.svg";
import successQR from "../../../../../assets/img/ready.svg";

import styles from "./LivenessValidationCard.module.scss";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { FirebaseUserLivenessValidation } from "../../../../../interfaces/types";
import { useSearchParams } from "react-router-dom";
import { saveToFirestoreLiveness } from "../utils";
import QRModal from "./QRModal";

type IdentityValidationCardState =
  | "INITIAL"
  | "DETECTOR"
  | "VALIDATED"
  | "NOT_VALIDATED"
  | "CAMERA_ACCESS_REQUIRED";

interface Props {
  token?: string;
}

const LivenessValidationCard = (props: Props) => {
  const {
    container,
    success: successStyle,
    error: errorStyle,
    warning,
    title,
    infoContainer,
    examplesContainer,
    validContainer,
    invalidContainer,
    icon,
    contentCenter,
    livenessContainer,
    livenessQRContainer,
    continueByQRButton,
    successPage,
  } = styles;

  const dispatch = useAppDispatch();

  const { accountOpeningForm, windowWidth } = useAppSelector((state) => state);
  const { formWithoutAllariaAccount } = accountOpeningForm;
  const { DNI, gender: storeGender } = formWithoutAllariaAccount[3];
  const { email } = formWithoutAllariaAccount[1];

  const attemptsLimit = 5;
  const [firebaseLivenessValidation, setFirebaseLivenessValidation] =
    useState<FirebaseUserLivenessValidation>({
      email,
      isLivenessValid: false,
      attemptsDesktop: 0,
      attemptsMobile: 0,
      scannedQR: false,
    });

  const { isLivenessValid, attemptsDesktop, attemptsMobile, scannedQR } =
    firebaseLivenessValidation;

  const db = getFirestore();

  const [cardState, setCardState] =
    useState<IdentityValidationCardState | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [qrImage, setQrImage] = useState("");

  let [searchParams] = useSearchParams();
  const urlToken = searchParams.get("token");
  const urlDNI = searchParams.get("dni");
  const urlGender = searchParams.get("gender");
  const continueByQR = !!urlToken;

  const token = props.token ?? urlToken;
  const DNINumber = DNI.number || urlDNI;
  const gender = storeGender || urlGender;

  const playerRef: LegacyRef<Player> | undefined = useRef(null);

  const isLimitExceeded =
    (continueByQR && attemptsMobile >= attemptsLimit) ||
    (!continueByQR && attemptsDesktop >= attemptsLimit);

  const isMobile = windowWidth.width < 768;

  const AWS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const AWS_SECRET = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  const credentialProvider: AwsCredentialProvider = async () => {
    return {
      accessKeyId: String(AWS_KEY),
      secretAccessKey: String(AWS_SECRET),
    };
  };

  const handleRequirementsClick = () => {
    dispatch(openModal({ name: "modal-minimum-requirements" }));
  };

  const handleQRClick = () => {
    setError(null);
    saveToFirestoreLiveness({
      ...firebaseLivenessValidation,
      scannedQR: false,
      token: token || "",
    });
    dispatch(openModal({ name: "modal-qr" }));
  };

  const handleAnalysisComplete = async () => {
    if (sessionId && token) {
      try {
        const livenessResult = await retrieveLivenessResult(token, sessionId);
        const { status, reference_image: referenceImage } = livenessResult.data;

        if (status === "OK") {
          const genderToSex = gender === "FEMALE" ? "F" : "M";
          const dni = String(DNINumber);

          const renaperImageData = await validateRenaperImage(
            referenceImage,
            dni,
            genderToSex,
            token
          );

          const { status: imageValidationStatus } = renaperImageData.data;

          if (imageValidationStatus === "HIT") {
            const url = `data:image/jpg;base64,${referenceImage}`;
            fetch(url)
              .then((res) => res.blob())
              .then(async (blob) => {
                const selfie = new File([blob], "selfie", {
                  type: "image/jpg",
                });
                await uploadPhoto(selfie, "SELFIE", token);

                handleSuccess();
              });
          } else {
            // NO-HIT
            handleError();
          }
        } else {
          // status = "CHECK"
          handleError();
        }
      } catch (error: any) {
        console.log(error);
        handleError();
      }
    }
  };

  const handleError = () => {
    const newAttempts = continueByQR
      ? {
          attemptsMobile: attemptsMobile + 1,
        }
      : {
          attemptsDesktop: attemptsDesktop + 1,
        };
    saveToFirestoreLiveness({
      ...firebaseLivenessValidation,
      ...newAttempts,
      token: token || "",
    });
    setCardState("NOT_VALIDATED");
  };

  const handleSuccess = () => {
    saveToFirestoreLiveness({
      ...firebaseLivenessValidation,
      isLivenessValid: true,
      token: token || "",
    });
  };

  const getErrorData = () => {
    let errorType: "WARNING" | "ERROR" = "ERROR";
    let errorMessage: ReactNode;

    if (isLimitExceeded) {
      errorType = "ERROR";
      errorMessage = (
        <span>
          Lo sentimos, no pudimos reconocerte.
          <br></br>
          <a
            href="https://wa.me/5491138499171"
            rel="noreferrer"
            target="_blank"
          >
            Contactá a soporte{" "}
          </a>
          para continuar.
        </span>
      );
      return { errorType, errorMessage };
    }

    switch (error) {
      case "CAMERA_FRAMERATE_ERROR":
        errorType = "WARNING";
        errorMessage = (
          <span>
            Tu cámara o buscador de internet no cumple los{" "}
            <p onClick={handleRequirementsClick}>requisitos mínimos</p>
          </span>
        );
        break;
      case "CONNECTION_TIMEOUT":
        errorType = "WARNING";
        errorMessage = <span>Buscá un lugar con mejor conexión.</span>;
        break;
      case "TIMEOUT":
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte. Rellená el óvalo con tu cara.
          </span>
        );
        break;
      case "FACE_DISTANCE_ERROR":
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte. Asegurate de no acercarte
            durante la captura.
          </span>
        );
        break;
      default:
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte.
            <br></br>
            Por favor, intentalo nuevamente.
          </span>
        );
    }

    return { errorType, errorMessage };
  };

  const { errorType, errorMessage } = getErrorData();

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, "userLivenessValidation", token || ""),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data() as FirebaseUserLivenessValidation;
          setFirebaseLivenessValidation(data);
          setIsLoading(false);
        }
      }
    );
    return unsub;
  }, []);

  useEffect(() => {
    if (
      token &&
      cardState &&
      ["INITIAL", "NOT_VALIDATED"].includes(cardState)
    ) {
      getSessionId(token)
        .then((res) => {
          const sessionId = res.data.sessionId;
          setSessionId(sessionId);
        })
        .catch((error: any) => {
          console.log(error);
          setError("SESSION_ID_ERROR");
        });
    }
  }, [token, cardState]);

  useEffect(() => {
    error &&
      (error === "CAMERA_ACCESS_ERROR"
        ? setCardState("CAMERA_ACCESS_REQUIRED")
        : handleError());
  }, [error]);

  useEffect(() => {
    if (!isLoading) {
      isLimitExceeded ? setCardState("NOT_VALIDATED") : setCardState("INITIAL");
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLivenessValid) {
      setCardState(null);
      setTimeout(() => {
        playerRef.current?.play();
      }, 1000);
      dispatch(closeModal());
    }
  }, [isLivenessValid]);

  useEffect(() => {
    if (!isMobile) {
      QRCode.toDataURL(
        `${window.location.origin}/liveness-validation?token=${token}&dni=${DNINumber}&gender=${gender}`,
        {
          color: {
            dark: "#224288",
          },
          margin: 0,
          width: 200,
        }
      ).then(setQrImage);
    }
  }, [isMobile, token, DNINumber, gender]);

  useEffect(() => {
    if (continueByQR && !isLoading) {
      saveToFirestoreLiveness({
        ...firebaseLivenessValidation,
        scannedQR: true,
        token: token || "",
      });
      localStorage.clear();
    }
  }, [continueByQR, isLoading]);

  return (
    <div className={continueByQR ? livenessQRContainer : livenessContainer}>
      <MinimumRequirementsModal />
      <QRModal qrImage={qrImage} scannedQR={scannedQR} />

      {sessionId && cardState === "DETECTOR" && (
        <div className="mb-4">
          <FaceLivenessDetectorCore
            sessionId={sessionId}
            region="us-east-1"
            onAnalysisComplete={handleAnalysisComplete}
            disableStartScreen={true}
            onError={(error) => {
              console.log(error);
              setError(error.state);
            }}
            displayText={livenessTexts["es"]}
            config={{ credentialProvider }}
          />
        </div>
      )}

      {cardState === "INITIAL" && (
        <div className={container}>
          <InfoAlert
            containerClassName={infoContainer}
            text={
              <div>
                <h4>Advertencia de fotosensitividad</h4>
                <span>
                  Este chequeo muestra luces de colores. Procedé con precaución.
                </span>
              </div>
            }
            variant={continueByQR ? "LIGHT" : "DARK"}
          />
          <div>
            <h3 className={title}>
              Seguí estas recomendaciones para completar la validación:
            </h3>
            <ul>
              <li>Subí el brillo de tu dispositivo.</li>
              <li>Sacate anteojos y cualquier otro accesorio facial.</li>
              <li>Buscá un lugar interior con buena iluminación.</li>
            </ul>
          </div>

          <div className={examplesContainer}>
            <div className={validContainer}>
              <img src={successIcon} alt="successIcon" className={icon} />
              <img src={validExample} alt="validExample" />
            </div>
            <div className={invalidContainer}>
              <img src={errorIcon} alt="errorIcon" className={icon} />
              <img src={invalidExample} alt="invalidExample" />
            </div>
          </div>
        </div>
      )}

      {isLivenessValid && !cardState && (
        <div
          className={`${container} ${contentCenter} ${
            !continueByQR ? successStyle : ""
          }`}
        >
          <Player
            onEvent={(event) => {
              if (event === "complete" || event === "error") {
                setTimeout(() => {
                  setCardState("VALIDATED");
                }, 500);
              }
            }}
            ref={playerRef}
            src={lottieJson}
            style={{
              width: "50%",
              height: "50%",
            }}
          />
        </div>
      )}

      {cardState === "VALIDATED" &&
        (continueByQR ? (
          <div className={successPage}>
            <img src={successQR} alt="Listo" />
            <h2>
              ¡Listo! ya podés cerrar esta página y seguir en tu computadora.
            </h2>
          </div>
        ) : (
          <div className={`${container} ${contentCenter} ${successStyle}`}>
            <img src={successIcon} alt="successIcon" />
            <span>
              ¡Validaste tu identidad!
              <br></br>
              Podés continuar.
            </span>
          </div>
        ))}

      {cardState === "NOT_VALIDATED" && (
        <div
          className={`${container} ${contentCenter} ${
            errorType === "ERROR" ? errorStyle : warning
          }`}
        >
          <img
            src={errorType === "ERROR" ? errorIcon : warningIcon}
            alt="icon"
          />
          {errorMessage}
        </div>
      )}

      {cardState === "CAMERA_ACCESS_REQUIRED" && (
        <div className={`${container} ${contentCenter}`}>
          <img src={cameraIcon} alt="cameraIcon" />
          <span>Permití el acceso a tu cámara</span>
        </div>
      )}

      {!isLimitExceeded && !["VALIDATED", null].includes(cardState) && (
        <Button
          text={
            cardState === "NOT_VALIDATED"
              ? "Reintentar captura"
              : "Comenzar captura"
          }
          buttonType="primary"
          type="button"
          width="100%"
          className={`mb-2 ${continueByQR ? continueByQRButton : ""}`}
          isLoading={!sessionId && !error}
          disabled={
            !!cardState &&
            (error === "CAMERA_FRAMERATE_ERROR" || cardState === "DETECTOR")
          }
          click={() => {
            setError(null);
            cardState === "INITIAL"
              ? setCardState("DETECTOR")
              : setCardState("INITIAL");
          }}
        />
      )}
      {!isMobile &&
        !continueByQR &&
        !["VALIDATED", null].includes(cardState) && (
          <Button
            text="Validar con mi teléfono"
            buttonType="secondary"
            type="button"
            width="100%"
            className={`${continueByQR ? continueByQRButton : ""}`}
            isLoading={!sessionId}
            disabled={attemptsMobile >= attemptsLimit}
            click={handleQRClick}
          />
        )}
    </div>
  );
};

export default LivenessValidationCard;
